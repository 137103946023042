import React from "react"
import { Toolbar, ToolbarItem, ToolbarSection, ToolbarSpacer } from "../Toolbar"
import ThreadPagination from "./ThreadPagination"
import ThreadPollButton from "./ThreadPollButton"
import ThreadPostsModeration from "./ThreadPostsModeration"
import ThreadReplyButton from "./ThreadReplyButton"
import ThreadShortcutsButton from "./ThreadShortcutsButton"

const ThreadToolbarTop = ({
  thread,
  posts,
  user,
  pollDisabled,
  selection,
  moderation,
  onPoll,
  onReply,
}) => (
  <Toolbar>
    <ToolbarSection className="hidden-xs">
      <ToolbarItem>
        <ThreadShortcutsButton posts={posts} thread={thread} user={user} />
      </ToolbarItem>
      <ToolbarItem>
        <ThreadPagination baseUrl={thread.url.index} posts={posts} />
      </ToolbarItem>
      <ToolbarItem>
    <select id="limitSwitch" className="btn btn-default" ><option value="">Posts per Page</option>
    {window.global.postsPerPage == 1 ? (<option value="1" selected="selected" >1</option>)
    : (<option value="1" >1</option>)
    }
    {window.global.postsPerPage == 5 ? (<option value="5" selected="selected" >5</option>)
    : (<option value="5" >5</option>)
    }   
    {window.global.postsPerPage == 10 ? (<option value="10" selected="selected" >10</option>)
    : (<option value="10" >10</option>)
    }   
    {window.global.postsPerPage == 20 ? (<option value="20" selected="selected" >20</option>)
    : (<option value="20" >20</option>)
    }   
    {window.global.postsPerPage == 30 ? (<option value="30" selected="selected" >30</option>)
    : (<option value="30" >30</option>)
    }   
    {window.global.postsPerPage == 40 ? (<option value="40" selected="selected" >40</option>)
    : (<option value="40" >40</option>)
    }   
    {window.global.postsPerPage == 50 ? (<option value="50" selected="selected" >50</option>)
    : (<option value="50" >50</option>)
    }   
    </select>
      </ToolbarItem>
    </ToolbarSection>
    <ToolbarSpacer />
    {thread.acl.can_start_poll && !thread.poll && (
      <ToolbarSection className="hidden-xs">
        <ToolbarItem>
          <ThreadPollButton disabled={pollDisabled} onClick={onPoll} />
        </ToolbarItem>
      </ToolbarSection>
    )}
    {thread.acl.can_reply ? (
      <ToolbarSection>
        <ToolbarItem className="hidden-sm hidden-md hidden-lg" shrink>
          <ThreadShortcutsButton posts={posts} thread={thread} user={user} />
        </ToolbarItem>
        <ToolbarItem>
          <ThreadReplyButton onClick={onReply} />
        </ToolbarItem>
        {thread.acl.can_start_poll && !thread.poll && (
          <ToolbarItem className="hidden-sm hidden-md hidden-lg" shrink>
            <ThreadPollButton
              disabled={pollDisabled}
              onClick={onPoll}
              compact
            />
          </ToolbarItem>
        )}
        {moderation.enabled && (
          <ToolbarItem className="hidden-xs" shrink>
            <ThreadPostsModeration
              thread={thread}
              user={user}
              selection={selection}
            />
          </ToolbarItem>
        )}
      </ToolbarSection>
    ) : (
      <ToolbarSection>
        <ToolbarItem className="hidden-sm hidden-md hidden-lg" shrink>
          <ThreadShortcutsButton posts={posts} thread={thread} user={user} />
        </ToolbarItem>
        {thread.acl.can_start_poll && !thread.poll && (
          <ToolbarItem>
            <ThreadPollButton disabled={pollDisabled} onClick={onPoll} />
          </ToolbarItem>
        )}
        {moderation.enabled && (
          <ToolbarItem shrink>
            <ThreadPostsModeration
              thread={thread}
              user={user}
              selection={selection}
            />
          </ToolbarItem>
        )}
      </ToolbarSection>
    )}
  </Toolbar>
)

export default ThreadToolbarTop
